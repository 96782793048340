@import "src/assets/scss/mixins";
@import "src/assets/scss/color_variables";

.toggle-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
      gap: 8px;

  .toggle-text {
    @include text;
  }
  .toggle-link-container {
    .toggle-link {
      @include text;
      color: $accent;
      text-decoration-line: underline;
    }
  }
}
