@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.empty-container {
  @include subtitle;

  display: inherit;
  flex-direction: column;
  gap: 24px;

  align-items: center;
}
