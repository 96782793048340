@import "src/assets/scss/mixins";

.auth-page-container {
  max-width: 350px;
  width: 100%;
  flex-grow: 1;

  display: flex;
  gap: 22px;
  flex-direction: column;
  justify-content: space-between;
}
.auth-block-container {
  display: flex;
  gap: 40px;
  flex-direction: column;

  // height: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    gap: 24px;
    height: 100%;

    .input-block-container {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .form-photo-true-container {
      max-width: 380px;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 40px;
      .description-text {
        @include text;
        line-height: 140%;
      }

      .photos-container {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    .form-photo-false-container {
      max-width: 380px;
      width: 100%;

      display: flex;
      flex-direction: column;
      gap: 10px;
      .lock-text-container {
        display: inherit;
        gap: 5px;
        color: #bfc0c7;
        @include text;
      }
      .description-text {
        @include text;
        line-height: 140%;
      }
    }
  }
}

label {
  width: 100%;
}

.gender-className {
  max-width: 280px !important;

  .ant-radio-wrapper {
    display: flex;
    gap: 10px;
  }
}

.figure-className {
  .ant-radio-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    margin-top: -30px;
    margin-right: 0;
  }
}

.btn-with-icon {
  display: flex;
  gap: 8px;
}
