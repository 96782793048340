@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.page-subtitle-text-container {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;

  display: flex;
}
