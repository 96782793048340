.main-container {
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-direction: column;

  height: 100vh;
  // max-height: 800px;
  box-sizing: border-box;
  padding: 48px 10px;

  overflow: auto;

  @media screen and (max-width: 640px) {
    padding: 24px 16px;
  }
}
