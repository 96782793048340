@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.select-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;
  // max-width: 280px;
  // width: 100%;
  max-width: 350px;
  background: #fff;

  .several-selects-container {
    display: inherit;
    gap: 10px;
  }
}

.ant-select {
  height: 50px;
}
.ant-select-selector {
  height: 50px !important;
  display: flex;
  align-items: center;
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
  .ant-select-selector {
  border-color: $accent !important;
}
