@mixin title {
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
}

@mixin subtitle {
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

@mixin big_text {
  font-size: 16px;
  line-height: 120%;
  font-weight: 400;
}

@mixin text {
  font-size: 14px;
  line-height: 120%;
  font-weight: 400;
}

@mixin clue_text {
  font-size: 12px;
  line-height: 140%;
  font-weight: 400;
}

@mixin truncate-lines($lines) {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  -webkit-box-orient: vertical;
}
