@import "src/assets/scss/mixins";
@import "src/assets/scss/color_variables";

label {
  color: #bfc0c7;
  font-size: 14px;
  line-height: 120%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.page-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  max-width: 350px;
  width: 100%;
  // height: 100%;

  flex-grow: 1;
}

.prev-next-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 350px;
  width: 100%;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  // bottom: -18px;
  bottom: -20px;
  left: 30px;
}

.not-absolute-error {
  position: initial;
  text-align: center;
}

.card-name {
  @include truncate-lines(1);
  @include text;
  word-break: break-word;
}
.card-subname {
  @include truncate-lines(1);
  @include text;
  color: #bfc0c7;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:hover,
.ant-input-search-button:hover,
.ant-input-search-button:active {
  border-color: $accent !important;
}

.ant-modal {
  font-family: "Nunito Sans", sans-serif;
  width: 400px !important;

  .ant-modal-content {
    padding: 16px !important;

    .ant-modal-header {
      margin-bottom: 20px !important;
    }

    .ant-modal-footer {
      margin-top: 120px !important;

      .ant-btn {
        padding: 0px 42px !important;
        height: 50px;
      }

      .ant-btn-primary {
        background-color: $accent;
        &:hover {
          opacity: 0.9;
        }
      }

      .ant-btn-default {
        border-color: #fff;
        color: $accent;
        box-shadow: 0 2px 0 rgba(0, 0, 0, 0);

        &:hover {
          border: 1px solid $accent;
        }
      }
    }
  }
}

@media screen and (max-width: 640px) {
  input,
  select,
  textarea {
    font-size: 16px !important;
  }
}

//container of errors in screens
.error-text-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  .error-header {
    @include title;
    text-align: center;
  }

  .error-text {
    @include big_text;
    text-align: center;
    line-height: 140%;
  }
}