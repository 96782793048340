@import "src/assets/scss/mixins";
@import "src/assets/scss/color_variables";

.welcome-first-container {
  display: inherit;
  gap: 40px;
  flex-direction: column;
  align-items: center;

  position: relative;

  // .example-container {
  //   z-index: -1;
  //   position: absolute;
  //   height: 400px;
  // }
}

.welcome-block-container {
  display: inherit;
  gap: 40px;
  flex-direction: column;

  flex-grow: 1;

  .welcome-text {
    @include big_text;
  }
  .section-container {
    display: inherit;
    gap: 24px;
    flex-direction: column;

    .section-title {
      @include subtitle;
    }

    .work-point-container {
      @include big_text;
      display: inherit;
      align-items: center;
      gap: 8px;
    }

    .stores-container {
      max-width: 280px;

      display: inherit;
      flex-direction: column;
      gap: 20px;
    }
  }
}

.work-clue {
  @include clue_text;
}
