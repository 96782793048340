@import "src/assets/scss/mixins";
@import "src/assets/scss/color_variables";

.category-card-container {
  position: relative;
  height: 75px;
  background-color: $image_background;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 5px;

  .category-remove {
    position: absolute;
    top: 2px;
    right: 2px;

    cursor: pointer;
  }
}
