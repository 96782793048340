.slide {
  width: 300px;
  height: 520px;
  transition: transform 0.5s ease;
  display: inherit;
  user-select: none;

  cursor: pointer;

  img {
    width: 100%;
    height: 520px;
    object-fit: cover;
    display: inherit;
    border-radius: 10px;
    pointer-events: none !important;
  }

  &.active {
    // z-index: 1;
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-carousel .slick-dots li {
  background: #cfd0d7;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  box-shadow: 0px 0px 7px 1px rgba(34, 60, 80, 0.2);

  button {
    background: #cfd0d7;
    border-radius: 5px;
    height: 8px;
    box-shadow: 0px 0px 7px 1px rgba(34, 60, 80, 0.2);
  }

  &.slick-active {
    background: #1c1c1c;
    width: 16px;

    button {
      background: #1c1c1c;
    }
  }
}
