@import "src/assets/scss/mixins";
@import "src/assets/scss/color_variables";

.section-container {
  display: inherit;
  flex-direction: column;
  gap: 16px;

  .cards-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 11px;

    @media screen and (max-width: 300px) {
      grid-template-columns: 1fr;
    }
  }
}

.popup-sizes-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .sizes-text {
    @include text;
    font-weight: 600 !important;
  }

  .sizes-container {
    display: flex;
    .size-container {
      width: 56px;
      height: 56px;
      border-radius: 8px;
      background-color: $white;
      color: $black;
      cursor: pointer;

      display: inherit;
      flex-direction: column;
      gap: 4px;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: 0px 4px 16px 0px rgba(142, 141, 208, 0.12) !important;

        // box-shadow: 0px 2px 20px rgba(202, 202, 224, 0.12);
      }
    }

    .active-size {
      background-color: $accent !important;
      color: $white !important;
    }
  }
}

.size-title-text {
  @include text;
  font-weight: 600 !important;
  color: $accent;
}
.size-text {
  @include text;
}
