@import "src/assets/scss/mixins";

.social-container {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .social-title {
    @include text;
    display: inherit;
    justify-content: center;
  }
  .social-buttons-container {
    display: inherit;
    justify-content: center;
    gap: 20px;

    .social-button {
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
