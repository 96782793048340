@import "src/assets/scss/global_styles";
@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.button {
  @include text;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 8px;
  padding: 17px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  line-height: 16px;
  width: 100%;

  &-filled {
    background: $accent;
    color: $white;

    &:hover {
      opacity: 0.9;
    }
  }

  &-primary {
    background: $black;
    color: $white;

    &:hover {
      opacity: 0.9;
    }
  }

  &-second {
    background: $accent_light;
    color: $accent;
  }

  &-text {
    background: $white;
    color: $accent;
    border: 1px solid $white;

    &:hover {
      border: 1px solid $accent;
    }
  }
}

.disabled {
  background-color: #B2B3B7;
  pointer-events:none;
}
