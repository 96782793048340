@import "src/assets/scss/mixins";

.photo-container {
  display: inherit;
  align-items: center;
  gap: 16px;

  .photo-button {
    border-radius: 8px;
    background: rgba(126, 116, 225, 0.1);
    width: 56px;
    height: 56px;

    position: relative;

    display: inherit;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    .image {
      width: 100%;
      border-radius: 8px;
      height: 100%;
      object-fit: cover;
    }

    .photo-remove {
      position: absolute;
      top: 2px;
      right: 2px;
    }
  }

  .photo-text-container {
    display: inherit;
    flex-direction: column;
    gap: 4px;

    .top-text {
      @include big_text;
    }
    .bottom-text {
      @include text;
      color: rgba(40, 40, 40, 0.4);
    }
  }
}
