@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.product-card-container {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .info-container {
    display: inherit;
    flex-direction: column;
    gap: 10px;

    .product-info-container {
      display: inherit;
      flex-direction: column;
      gap: 2px;
    }

    .card-button {
      @include text;
      border-radius: 8px;
      background: #252525;
      padding: 9px 16px;
      color: $white;
    }
  }
}

.image-container {
  height: 220px;
  background-color: $image_background;
  border-radius: 8px;

  .image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
}
