@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.radio-group-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 10px;

  .ant-radio-group {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  .ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
    border-color: $accent;
    background-color: $white;

    &::after {
      background-color: $accent;
    }
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-radio-wrapper
  span.ant-radio
  + * {
  padding: 0;
}
