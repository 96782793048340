@import "color_variables"
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;500;600;700&display=swap')

* 
    margin: 0
    padding: 0

body
    margin: 0
    padding: 0
    font-family: 'Nunito Sans', sans-serif
    background: $background   
    color: $black 

button, input
    font-family: 'Nunito Sans', sans-serif !important