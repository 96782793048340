@import "src/assets/scss/color_variables.sass";
@import "src/assets/scss/mixins.scss";

.error-page-container {
  width: 100%;
  // height: 80vh;
  // margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;

  .error-text {
    @include subtitle;
    text-align: center;
  }

  .back-container {
    @include text;
    padding-top: 20px;

    display: inherit;
    align-items: center;
    gap: 6px;

    color: $accent;
    cursor: pointer;
  }
}
