@import "src/assets/scss/mixins";

.fitting-block-container {
  display: inherit;
  gap: 8px;
  flex-direction: column;
  flex-grow: 1;

  .fitting-title {
    @include big_text;
  }

  .params-back-container {
    @include text;
    display: inherit;
    gap: 4px;

    cursor: pointer;
  }

  .slider-container {
    margin: 8px 0 30px 0;
  }

  .complete-fit-container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 10px;
  }
}

.popup-description-container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  .description-text-container {
    @include big_text;
  }

  .description-stores-container {
    display: inherit;
    gap: 10px;
  }
}

.popup-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;

  @media screen and (max-width: 375px) {
  }
  .image-container {
    position: relative;
    max-width: 96px;
    max-height: 96px;
    .popup-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;

      &.selected {
        // border: 1px solid black;
      }
    }

    .check-icon {
      position: absolute;
      top: 8px;
      right: 8px;
      filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4));
    }
  }
}

.ant-modal-header {
  white-space: pre-line;

  margin-bottom: 60px;
}
