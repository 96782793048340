@import "src/assets/scss/color_variables";

.page-header-text-container {
  font-size: 28px;
  font-weight: 700;
  line-height: 140%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 640px) {
    font-size: 24px;
  }
}
