@import "src/assets/scss/color_variables";
@import "src/assets/scss/mixins";

.input_container {
  position: relative;
  display: flex;
  // flex: 1;
  flex-direction: column;
  gap: 10px;

  // max-width: 280px;
  width: 100%;

  .toggle-password {
    position: absolute;
    top: 42px;
    right: 16px;

    .icon {
      color: #bab3bc;
      cursor: pointer;
    }
  }

  input,
  textarea,
  select {
    box-sizing: border-box;
    width: 100%;
    @include text;
    background: none;
    border: none;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 8px;
    border: 1px solid #d8dcdf;

    display: flex;
    padding: 16px;
    align-items: center;

    &:focus {
      border: 1px solid $accent;
    }
  }

  ::placeholder {
    color: #bab3bc;
  }

  .input-error {
    border-color: red;
  }

  .ant-input {
    border: 0px !important;
  }
}

.search_input_container {
  .ant-input-affix-wrapper {
    padding: 12px !important;
  }
  .ant-input-group-addon{
    .ant-btn{
      width: 50px;
      height: 50px;
    }
  }
}

:where(.css-dev-only-do-not-override-1m62vyb).ant-input-search
  > .ant-input-group
  > .ant-input-group-addon:last-child
  .ant-input-search-button:not(.ant-btn-primary) {
  &:hover {
    color: $accent !important;
  }
}
